<template>
	<div>
		<slot
			v-for="(crumb, index) of crumbs"
			:crumb="crumb"
			:isLast="index === crumbs.length -1"
		/>
	</div>
</template>

<script>
  export default {
    name: "DropdownBreadcrumbs",
    props: {
      crumbs:{
        type: Array
      }
    }
  }
</script>

<style scoped>

</style>